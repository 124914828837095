import React, { useState, useRef, useEffect } from 'react';
import './RoutePointsItem.scss';

export default function RoutePointsItem({ index, id, points, state, setState }) {
  const input = useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [place, setPlace] = useState(null);
  const [removeVisible, setRemoveVisible] = useState(false);
  const item = useRef(null);

  const removeItem = () => {
    setState(state.filter(item => item.id !== id));
    points.current = points.current.filter((item, idx) => idx !== index);
  };

  const restoreValue = () => {
    const address = autocomplete.getPlace()?.formatted_address;

    if (address) {
      input.current.value = address;
    }
  };

  const forceRefresh = () => {
    input.current.value = `${input.current.value} `;
    input.current.dispatchEvent(new Event('input', { bubbles: true }));
    input.current.value = input.current.value.trim();
  };

  useEffect(() => {
    if (input.current && !autocomplete) {
      const instance = new window.google.maps.places.Autocomplete(input.current, {
        types: ['(cities)'],
        componentRestrictions: { country: 'PL' },
        language: 'pl'
      });

      setAutocomplete(instance);

      input.current.addEventListener('focus', () => {
        const pacContainer = document.querySelectorAll('.pac-container');

        pacContainer.forEach((el) => el.classList.add('route-points__autocompeler'));
      });

      input.current.addEventListener('blur', () => {
        const pacContainer = document.querySelectorAll('.pac-container');

        pacContainer.forEach((el) => el.classList.remove('route-points__autocompeler'));
      });
    }
  }, [input, autocomplete]);

  useEffect(() => {
    if (autocomplete && !place) {
      autocomplete.addListener('place_changed', () => {
        const placeInfo = autocomplete.getPlace();
        const newPoints = [...points.current];
        const wrap = item.current.closest('.route-points');
        const items = wrap.querySelectorAll('.route-points__wrap > div');
        const index = Array.from(items).indexOf(item.current.parentElement);

        newPoints[index] = {
          id,
          address: placeInfo.formatted_address,
          coords: {
            lat: placeInfo.geometry?.location.lat(),
            lng: placeInfo.geometry?.location.lng()
          }
        };

        input.current.value = placeInfo.formatted_address;

        setPlace(placeInfo);

        points.current = newPoints;
      });

      input.current.addEventListener('keydown', (e) => {
        if (e.keyCode === 13 && e.isTrusted) {
          setTimeout(() => {
            input.current.dispatchEvent(new Event('input', { bubbles: true }));

            setTimeout(() => {
              const eventArrowDown = new KeyboardEvent('keydown', {
                key: 'ArrowDown',
                keyCode: 40,
                bubbles: true,
                cancelable: true
              });

              input.current.dispatchEvent(eventArrowDown);

              const eventEnter = new KeyboardEvent('keydown', {
                key: 'Enter',
                keyCode: 13,
                bubbles: true,
                cancelable: true
              });

              input.current.dispatchEvent(eventEnter);
            }, 0);
          }, 0);
        }
      });
    }
  }, [autocomplete, place, index, points, id]);

  return (
    <div className="route-points__item" ref={item}>
      <span className="route-points__handler">
        <img src="/images/icons/common/drag-handler.svg" alt="chwyć aby przenieść" />
      </span>
      <div className="route-points__input-wrap" onMouseEnter={() => setRemoveVisible(true)} onMouseLeave={() => setRemoveVisible(false)}>
        <input className="route-points__input" ref={input} onBlur={restoreValue} onFocus={forceRefresh} />
        <button onClick={removeItem} type="button" className={`route-points__remove ${removeVisible && state.length > 2 ? 'route-points__remove--visible' : ''}`}>
          <img src="/images/icons/common/trash.svg" alt="ikona śmietnika" />
        </button>
      </div>
    </div>
  );
}
