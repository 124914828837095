import React, { useState } from 'react';
import './Filters.scss';

export const featuresConfig = [
  {
    key: 'fuels',
    name: 'Paliwa',
    items: [
      {
        icon: '/images/icons/fuels/95.svg',
        label: 'Pb 95',
        id: 'gas_95'
      },
      {
        icon: '/images/icons/fuels/98.svg',
        label: 'Pb 98',
        id: 'gas_98'
      },
      {
        icon: '/images/icons/fuels/on.svg',
        label: 'Diesel',
        id: 'diesel'
      },
      {
        icon: '/images/icons/fuels/on-gold.svg',
        label: 'Diesel Gold',
        id: 'diesel_gold'
      },
      {
        icon: '/images/icons/fuels/lpg.svg',
        label: 'LPG',
        id: 'lpg'
      },
      {
        icon: '/images/icons/fuels/adblue.svg',
        label: 'AdBlue',
        id: 'adblue'
      }
    ]
  },
  {
    key: 'cards',
    name: 'Karty flotowe',
    items: [
      {
        icon: '/images/icons/cards/avia.svg',
        label: 'AVIA Card',
        id: 'avia'
      },
      {
        icon: '/images/icons/cards/uta.svg',
        label: 'UTA',
        id: 'uta'
      },
      {
        icon: '/images/icons/cards/dkv.svg',
        label: 'DKV',
        id: 'dkv'
      },
      {
        icon: '/images/icons/cards/eurowag.svg',
        label: 'Eurowag',
        id: 'eurowag'
      },
      {
        icon: '/images/icons/cards/e100.svg',
        label: 'E100',
        id: 'e100'
      }
    ]
  },
  {
    key: 'services',
    name: 'Usługi',
    items: [
      {
        icon: '/images/icons/services/24h.svg',
        label: '24h',
        id: 'open_24h'
      },
      {
        icon: '/images/icons/services/eat.svg',
        label: 'Eat & Go',
        id: 'eat_and_go'
      },
      {
        icon: '/images/icons/services/spar.svg',
        label: 'SPAR',
        id: 'spar'
      },
      {
        icon: '/images/icons/services/car-wash.svg',
        label: 'Myjnia A',
        id: 'car_wash_a'
      },
      {
        icon: '/images/icons/services/car-wash.svg',
        label: 'Myjnia R',
        id: 'car_wash_r'
      },
      {
        icon: '/images/icons/services/tir-wash.svg',
        label: 'Myjnia TIR',
        id: 'car_wash_tir'
      },
      {
        icon: '/images/icons/services/tir-parking.svg',
        label: 'Parking TIR',
        id: 'tir_parking'
      },
      {
        icon: '/images/icons/services/tir-entry-ok.svg',
        label: 'Wjazd TIR - OK',
        id: 'tir_entry_ok'
      },
      {
        icon: '/images/icons/services/tir-difficult-entry.svg',
        label: 'Wjazd TIR - Utrudniony',
        id: 'tir_difficult_entry'
      },
      {
        icon: '/images/icons/services/high-speed.svg',
        label: 'High Speed',
        id: 'high_speed'
      },
      {
        icon: '/images/icons/services/charging-station.svg',
        label: 'Stacja ładowania',
        id: 'charging_station'
      }
    ]
  }
];

export default function Filters({
  closed,
  absolute,
  stations,
  activeFilters,
  setActiveFilters
}) {
  const [filters, setFilters] = useState(activeFilters);
  const [filtersOpen, setFiltersOpen] = useState(!closed);

  const toggleFilter = (id) => {
    if (filters?.includes(id)) {
      setFilters(filters.filter(filter => filter !== id));
    } else {
      setFilters(filters ? [...filters, id] : [id]);
    }
  };

  const applyFilters = (newFilters) => {
    setActiveFilters(newFilters || filters);

    setFiltersOpen(false);
  };

  return (
    <div className={`filters ${absolute ? 'filters--absolute' : ''}`}>
      <div className="filters__head">
        <span className="filters__head-button" onClick={() => setFiltersOpen(!filtersOpen)}>
          <strong>Filtry</strong>
          <span>(wybrano {activeFilters?.length || 0})</span>
          <img className={`filters__head-arrow ${filtersOpen ? 'filters__head-arrow--reversed' : ''}`} src="/images/icons/common/arrow-down.svg" alt="strzałka" />
        </span>
        <button className="filters__clear-button" type="button" onClick={() => { setFilters([]); applyFilters([]) }}>Wyczyść filtry</button>
      </div>
      <div className="filters__lists-wrap">
        <div className={`filters__slider ${!filtersOpen ? 'filters__slider--closed' : ''}`}>
          <div className="filters__lists">
            {featuresConfig.map((group) => (
              <React.Fragment key={group.name}>
                <strong>{group.name}</strong>
                <ul className="filters__list">
                  {group.items.map((item) => (
                    <li onClick={() => toggleFilter(item.id)} key={item.id}>
                      <div className={`filters__checkbox ${filters?.includes(item.id) ? 'filters__checkbox--active' : ''}`}>
                        <input checked={filters?.includes(item.id) || false} onChange={() => toggleFilter(item.id)} type="checkbox" />
                      </div>
                      <img className="filters__icon" src={item.icon} alt={item.label} />
                      <span>{item.label}</span>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </div>
          <div className="filters__actions">
            <button className="filters__clear-button" type="button" onClick={() => { setFilters([]); applyFilters([]) }}>Wyczyść filtry</button>
            <button className="button" type="button" onClick={() => applyFilters()}>Zastosuj filtry</button>
          </div>
          {stations && (
            <div className="filters__slot">
              {stations}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
