import { useRef, useEffect, useState } from 'react';
import './FindStation.scss';
import Filters from '../../filters/Filters';
import StationList from '../../stations/list/StationList';

export default function FindStation({
  activeFilters,
  setActiveFilters,
  setFindStationTarget,
  findStationTarget,
  currentStation,
  setCurrentStation,
  stationsInScope,
  yourPosition
}) {
  const input = useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isGeolocalizationLoading, setIsGeolocalizationLoading] = useState(false);
  const wasEnterClicked = useRef(false);

  const restoreValue = () => {
    const address = autocomplete.getPlace()?.formatted_address;

    if (address) {
      input.current.value = address;
    }
  };

  const forceRefresh = () => {
    input.current.value = `${input.current.value} `;
    input.current.dispatchEvent(new Event('input', { bubbles: true }));
    input.current.value = input.current.value.trim();
  };

  const getLocalization = () => {
    setIsGeolocalizationLoading(true);

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const findStationTarget = {
            geometry: {
              location: {
                lat: () => lat,
                lng: () => lng,
              }
            }
          }

          input.current.value = `Twoja lokalizacja: ${lat} ${lng}`;

          setFindStationTarget(findStationTarget);
          setIsGeolocalizationLoading(false);
        },
        (error) => {
          console.error('Error getting user location:', error);

          setIsGeolocalizationLoading(false);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');

      setIsGeolocalizationLoading(false);
    }
  }

  useEffect(() => {
    if (input.current && !autocomplete) {
      const ac = new window.google.maps.places.Autocomplete(input.current, {
        types: ['(cities)'],
        componentRestrictions: { country: 'PL' },
        language: 'pl'
      });

      ac.addListener('place_changed', () => {
        const place = ac.getPlace();

        if (place.formatted_address) {
          input.current.value = place.formatted_address;

          if (wasEnterClicked.current) {
            wasEnterClicked.current = false;

            setFindStationTarget(place);
          }
        }
      });

      setAutocomplete(ac);

      input.current.addEventListener('keydown', (e) => {
        if (e.keyCode === 13 && e.isTrusted) {
          wasEnterClicked.current = true;

          setTimeout(() => {
            input.current.dispatchEvent(new Event('input', { bubbles: true }));

            setTimeout(() => {
              const eventArrowDown = new KeyboardEvent('keydown', {
                key: 'ArrowDown',
                keyCode: 40,
                bubbles: true,
                cancelable: true
              });

              input.current.dispatchEvent(eventArrowDown);

              const eventEnter = new KeyboardEvent('keydown', {
                key: 'Enter',
                keyCode: 13,
                bubbles: true,
                cancelable: true
              });

              input.current.dispatchEvent(eventEnter);
            }, 0);
          }, 0);
        }
      });
    }
  }, [input, autocomplete]);

  return (
    <div className="find-station">
      <div className="find-station__input">
        <button type="button" className="find-station__localize-button" onClick={getLocalization} title="Twoja lokalizacja">
          {!isGeolocalizationLoading ? (
            <img src="/images/icons/common/gps.svg" alt="Twoja lokalizacja" />
          ) : (
            <div className="loader" />
          )}
        </button>
        <input
          className="find-station__input-field" type="text" ref={input}
          placeholder="Wpisz nazwę miejscowości"
          onBlur={restoreValue}
          onFocus={forceRefresh}
        />
        <button className="find-station__input-button" type="button" onClick={() => setFindStationTarget(autocomplete?.getPlace())} />
        {findStationTarget && (
          <button className="find-station__input-clear" type="button" onClick={() => { setFindStationTarget(null); input.current.value = '' }} />
        )}
      </div>
      <Filters
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        stations={
          <StationList
            setCurrentStation={setCurrentStation}
            currentStation={currentStation}
            stationsInScope={stationsInScope}
            activeFilters={activeFilters}
            yourPosition={yourPosition}
            context="byStation"
          />
        }
      />
    </div>
  );
}
