import React, { useEffect } from 'react';
import './StationProfile.scss';
import { featuresConfig } from '../../filters/Filters';

export default function StationProfile({ station }) {
  const featuresConfigFlat = featuresConfig.map(cfg => cfg.items).flat();
  const {
    logo: {
      data: {
        attributes: {
          url: logo
        }
      }
    },
    address: {
      street,
      street_number,
      postal_code,
      city
    },
    station_number,
    google_maps_url,
    opening_hours,
    phone,
    warning,
    features,
    is_avia,
    name
  } = station.attributes;

  useEffect(() => {
    window.parent.postMessage({
      type: 'open-station',
      data: { name }
    }, '*');

    return () => {
      window.parent.postMessage({
        type: 'close-station',
        data: { name }
      }, '*');
    };
  }, []);

  return (
    <div className="station-profile">
      <div className="station-profile__head">
        <img className="station-profile__logo" src={`${process.env.REACT_APP_API_URL}/${logo}`} alt="logo" />
        <div className="station-profile__address">
          {!is_avia && <span className="station-profile__partner">Stacja partnerska</span>}
          <strong>{city}</strong>
          <span>{street}</span>
        </div>
        <div className="station-profile__number">
          <span>Numer stacji</span>
          <strong>{station_number}</strong>
        </div>
      </div>
      <div className="station-profile__content">
        <div className="station-profile__left-col">
          <div className={`station-list-item__hours ${opening_hours.is_24h ? 'station-list-item__hours--24h' : ''}`}>
            <img src="/images/icons/common/clock.svg" alt="Ikona zegara" />
            {opening_hours.is_24h ? '24h' : (
              <>
                {!!opening_hours.working_days_start && <>pon-pt: {opening_hours.working_days_start.slice(0, 5)} - {opening_hours.working_days_end.slice(0, 5)}<br /></>}
                {!!opening_hours.saturday_start && <>sob: {opening_hours.saturday_start.slice(0, 5)} - {opening_hours.working_days_end.slice(0, 5)}<br /></>}
                {!!opening_hours.sunday_start && <>niedz: {opening_hours.sunday_start.slice(0, 5)} - {opening_hours.working_days_end.slice(0, 5)}<br /></>}
              </>
            )}
          </div>
          <div className="station-profile__full-address">
            <img src="/images/icons/common/location.svg" alt="Ikona telefonu" />
            {street} {street_number}<br />
            {postal_code}<br />
            {city}
          </div>
          <div className="station-profile__tel">
            <img src="/images/icons/common/phone.svg" alt="Ikona telefonu" />
            <a href={`tel:${phone}`}>{phone}</a>
          </div>
        </div>
        <div className="station-profile__right-col">
          <div className="station-profile__drive">
            <a className="station-profile__drive-button" href={google_maps_url} target="_blank" rel="noreferrer">
              Jak dojechać
              <img src="/images/icons/common/arrow-top-right.svg" alt="Strzałka" />
            </a>
          </div>
        </div>
        {!!warning && (
          <div className="station-profile__alert">
            <img src="/images/icons/common/warning.svg" alt="Ikona alertu" />
            <p>{warning}</p>
          </div>
        )}
        <div className="station-profile__features">
          {featuresConfig.map((featureConfig) => (
            <React.Fragment key={featureConfig.key}>
              <h3 className="station-profile__features-head">{featureConfig.name}</h3>
              <ul className="station-profile__features-list">
                {
                  Object.entries(features[featureConfig.key])
                    .filter(([id, isAvailable]) => isAvailable && id !== 'id')
                    .map(([id]) => (
                      <li key={id}>
                        {
                          (() => {
                            const item = featuresConfigFlat.find(cfg => cfg.id === id);

                            return (
                              <>
                                <img src={item.icon} alt={item.label} />
                                {item.label}
                              </>
                            );
                          })()
                        }
                      </li>
                    ))
                }
              </ul>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
