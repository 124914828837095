import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { MarkerClusterer, GridAlgorithm } from '@googlemaps/markerclusterer';
import './Map.scss';
import StationListItem from '../stations/list/item/StationListItem';
import mapStyles from './mapStyles.json';

let timeout;
let prevPopup;

export default function Map({
  stations,
  currentStation,
  setCurrentStation,
  routePoints,
  setStationsInScope,
  stationsInScope,
  activeFiltersFindStation,
  activeFiltersPlanRoute,
  findStationTarget,
  context,
  yourPosition
}) {
  const [map, setMap] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [pointMarkers, setPointMarkers] = useState([]);
  const [stationMarkers, setStationMarkers] = useState([]);
  const [popup, setPopup] = useState({ isVisible: false });
  const routePointsPrev = useRef(null);
  const mapElement = useRef(null);
  const [prevZoom, setPrevZoom] = useState(null);
  const [prevCenter, setPrevCenter] = useState(null);
  const [, setRoutePath] = useState(null);
  const [clusterer, setClusterer] = useState(null);

  const createPointMarker = (position, map) => {
    return new window.google.maps.Marker({
      position: position,
      map: map,
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: '#fff',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#000',
        strokeOpacity: 0.7,
        scale: 6
      }
    });
  };

  const showMarker = (e, anchorY) => {
    if (document.querySelector('.station-profile')) {
      return;
    }

    const mapRect = mapElement.current.getBoundingClientRect();
    const markerRect = e.domEvent.srcElement.getBoundingClientRect();
    const x = markerRect.left - mapRect.left + markerRect.width / 2;
    const y = markerRect.top - mapRect.top - (62 - anchorY);

    clearTimeout(timeout);

    prevPopup = {
      station: stations.find(station => parseFloat(station.attributes.coordinates.latitude) === e.latLng.lat() && parseFloat(station.attributes.coordinates.longitude) === e.latLng.lng()),
      x,
      y,
      isVisible: true
    };

    setPopup(prevPopup);
  };

  const hideMarker = () => {
    timeout = setTimeout(() => setPopup({ ...prevPopup, isVisible: false }), 20);
  };

  const createStationMarker = (position, map, image, width, height, anchorX, anchorY, label) => {
    const marker = new window.google.maps.Marker({
      position,
      map,
      icon: {
        url: image,
        scaledSize: new window.google.maps.Size(width, height),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(anchorX, anchorY),
      },
      ...(!label ? {} : {
        label: {
          text: String(label),
          color: 'white'
        }
      })
    });

    marker.addListener('mouseover', (e) => showMarker(e, anchorY));
    marker.addListener('mousedown', (e) => showMarker(e, anchorY));
    marker.addListener('mouseout', hideMarker);

    return marker;
  };

  const activeFilters = useMemo(() => {
    return context === 'byStation' ? activeFiltersFindStation : activeFiltersPlanRoute;
  }, [activeFiltersPlanRoute, activeFiltersFindStation, findStationTarget, context]);

  const filteredStations = useMemo(() => {
    if (!activeFilters?.length) {
      return stations;
    }

    const res = stations?.filter((station) => {
      const features = Object.values(station.attributes.features)
        .filter(val => typeof val === 'object')
        .map(item => Object.entries(item))
        .flat()
        .filter(([key, val]) => val && typeof val === 'boolean')
        .filter(([key, val]) => val && typeof val === 'boolean')
        .map(([key, val]) => key);

      return activeFilters?.every(f => features.includes(f));
    });

    return res;
  }, [activeFilters, stations, context]);

  const defaultProps = useMemo(() => ({
    center: {
      lat: 52.2,
      lng: 19.4804
    },
    zoom: window.matchMedia('(max-width: 1023px)').matches ? 5 : 6.5,
    styles: mapStyles
  }), []);

  const isPointInScope = (checkPoint, centerPoint, km) => {
    const ky = 40000 / 360;
    const kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
    const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;

    return Math.sqrt(dx * dx + dy * dy) <= km;
  }

  const checkPointOnRoute = useCallback((point, routePath) => {
    return routePath.some((checkPoint) => isPointInScope({ lat: checkPoint.lat(), lng: checkPoint.lng() }, point, 6));
  }, []);

  const drawStationMarkersByRoutePath = (routePath) => {
    const stationMarkersTemp = [];

    if (filteredStations) {
      filteredStations.forEach((station) => {
        const { latitude: lat, longitude: lng } = station.attributes.coordinates;
        const position = { lat: parseFloat(lat), lng: parseFloat(lng) };

        if (checkPointOnRoute(position, routePath)) {
          // const logo = station.attributes.logo.data.attributes.url;

          stationMarkersTemp.push(createStationMarker(position, map, `/images/icons/common/marker-${station.attributes.is_avia ? 'red' : 'white'}.svg`, 46, 62, 23, 62));
          // stationMarkersTemp.push(createStationMarker(position, map, `${process.env.REACT_APP_API_URL}/${logo}`, 20, 20, 10, 49));
        }
      });

      setStationMarkers(stationMarkersTemp);
    }
  }

  const drawStationMarkersByStation = (station) => {
    const stationMarkersTemp = [];

    const { latitude: lat, longitude: lng } = station.attributes.coordinates;
    const position = { lat: parseFloat(lat), lng: parseFloat(lng) };
    // const logo = station.attributes.logo.data.attributes.url;

    stationMarkersTemp.push(createStationMarker(position, map, `/images/icons/common/marker-${station.attributes.is_avia ? 'red' : 'white'}.svg`, 46, 62, 23, 62));
    // stationMarkersTemp.push(createStationMarker(position, map, `${process.env.REACT_APP_API_URL}/${logo}`, 20, 20, 10, 49));

    return stationMarkersTemp;
  }

  const updateStationsInScope = useCallback((routePath) => {
    const stationsInScopeTemp = [];

    filteredStations.forEach((station) => {
      const { latitude: lat, longitude: lng } = station.attributes.coordinates;
      const position = { lat: parseFloat(lat), lng: parseFloat(lng) };

      if (checkPointOnRoute(position, routePath)) {
        stationsInScopeTemp.push(station);
      }
    });

    drawStationMarkersByRoutePath(routePath);

    setStationsInScope({
      ...stationsInScope,
      byRoute: stationsInScopeTemp
    });
  }, [filteredStations]);

  const calculateAndDisplayRoute = useCallback((directionsService, directionsRenderer) => {
    const points = [...routePoints];

    directionsService.route(
      {
        origin: points.shift(),
        destination: points.pop(),
        travelMode: 'DRIVING',
        waypoints: points.map(point => ({
          location: {
            lat: point.lat,
            lng: point.lng
          },
          stopover: true
        }))
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response);
          directionsRenderer.setOptions({ polylineOptions: { strokeColor: 'rgba(2, 29, 73, 0.25)', strokeWeight: 8 } });

          const rp = response.routes[0].overview_path;

          stationMarkers.forEach(marker =>  marker.setMap(null));

          setRoutePath(rp);
          updateStationsInScope(rp);
        } else {
          console.error('Directions request failed due to ' + status);
        }
      }
    );
  }, [checkPointOnRoute, routePoints, setStationsInScope, filteredStations, map, updateStationsInScope]);

  const initMap = useCallback(() => {
    const ds = new window.google.maps.DirectionsService();
    const dr = new window.google.maps.DirectionsRenderer({
      suppressMarkers: true
    });
    const mp = new window.google.maps.Map(document.getElementById('map'), defaultProps);

    mp.addListener('drag', () => {
      setPopup({ ...popup, isVisible: false });
    });

    setDirectionsService(ds);
    setDirectionsRenderer(dr);
    setMap(mp);

    dr.setMap(mp);
  }, [defaultProps]);

  const hidePopupOnZoom = (e) => {
    const wheelEvt = new WheelEvent('wheel', { bubbles: true, cancelable: true });

    Object.defineProperty(wheelEvt, 'deltaY', {
      value: e.deltaY,
      writable: false
    });

    Object.defineProperty(wheelEvt, 'clientX', {
      value: e.clientX,
      writable: false
    });

    Object.defineProperty(wheelEvt, 'clientY', {
      value: e.clientY,
      writable: false
    });

    mapElement.current.querySelector('.gm-style > div').dispatchEvent(wheelEvt);

    setPopup({ ...popup, isVisible: false });
  };

  useEffect(() => {
    initMap();

    document.querySelector('.tabs').addEventListener('touchstart', hideMarker);

    return () => {
      document.querySelector('.tabs').removeEventListener('touchstart', hideMarker);
    }
  }, [initMap]);

  useEffect(() => {
    if (routePoints && routePoints.length && context === 'byRoute') {
      directionsRenderer.setMap(map);

      clusterer.clearMarkers();

      routePointsPrev.current = routePoints;

      pointMarkers.forEach(marker =>  marker.setMap(null));
      stationMarkers.forEach(marker =>  marker.setMap(null));

      const pointMarkersTemp = routePoints.map(point => createPointMarker(point, map));

      setPointMarkers(pointMarkersTemp);
      calculateAndDisplayRoute(directionsService, directionsRenderer);
    }
  }, [routePoints, calculateAndDisplayRoute, directionsService, directionsRenderer, map, context]);

  useEffect(() => {
    if (map && filteredStations && context === 'byStation') {
      directionsRenderer.setMap(null);

      pointMarkers.forEach(marker =>  marker.setMap(null));
      stationMarkers.forEach(marker =>  marker.setMap(null));

      const latPoint = findStationTarget?.geometry.location.lat();
      const lngPoint = findStationTarget?.geometry.location.lng();
      const stationsInScopeTemp = [];
      const stationMarkersTemp = [];

      filteredStations.forEach((station) => {
        if (!findStationTarget) {
          stationsInScopeTemp.push(station);
        }
        stationMarkersTemp.push(...drawStationMarkersByStation(station));
      });

      if (findStationTarget) {
        filteredStations.forEach((station) => {
          const { latitude: lat, longitude: lng } = station.attributes.coordinates;

          if (isPointInScope({ lat, lng }, { lat: latPoint, lng: lngPoint }, 30)) {
            stationsInScopeTemp.push(station);
          }
        });

        map.setCenter({ lat: latPoint, lng: lngPoint });
        map.setZoom(11);
      } else {
        map.setCenter({
          lat: 52.2,
          lng: 19.4804
        });
        map.setZoom(defaultProps.zoom);
      }

      setStationMarkers(stationMarkersTemp);

      if (!clusterer) {
        const clustererInstance = new MarkerClusterer({
          markers: stationMarkersTemp,
          map,
          zoomOnClick: false,
          algorithm: new GridAlgorithm({ maxDistance: 150 }),
          renderer: {
            render: (cluster, stats, map) => {
              return createStationMarker(
                { lat: cluster.position.lat(), lng: cluster.position.lng() },
                map, '/images/icons/common/cluster-bg.svg',
                42, 42, 21, 21,
                cluster.markers.length
              )
            }
          }
        });

        setClusterer(clustererInstance);
      } else {
        clusterer.clearMarkers();
        clusterer.addMarkers(stationMarkersTemp);
      }

      setStationsInScope({
        ...stationsInScope,
        byStation: stationsInScopeTemp
      });
    }
  }, [findStationTarget, map, filteredStations, context]);

  useEffect(() => {
    if (currentStation) {
      const lat = parseFloat(currentStation.attributes.coordinates.latitude);
      const lng = parseFloat(currentStation.attributes.coordinates.longitude);

      setPrevZoom(map.getZoom());
      setPrevCenter(map.getCenter());

      map.setCenter({ lat, lng });
      map.setZoom(16);
    } else {
      prevCenter && map?.setCenter(prevCenter);
      prevZoom && map?.setZoom(prevZoom);
    }
  }, [currentStation, map]);

  return (
    <div className="map">
      <div ref={mapElement} id="map" style={{ height: '100%', width: '100%' }} />
      <div
        className="map__popup"
        onMouseLeave={() => setPopup({ ...popup, isVisible: false })}
        onTouchEnd={() => setTimeout(() => setPopup({ ...popup, isVisible: false }), 20)}
        onMouseEnter={() => clearTimeout(timeout)}
        onWheel={hidePopupOnZoom}
        style={{
          left: `${popup.x}px`,
          top: `${popup.y}px`
        }}
      >
        <div className={`map__popup-wrap ${popup.isVisible ? 'map__popup-wrap--active' : ''}`}>
          {popup.station && (
            <StationListItem yourPosition={yourPosition} routePoints={routePoints} station={popup.station} setCurrentStation={setCurrentStation} currentStation={currentStation} />
          )}
        </div>
      </div>
    </div>
  );
}
