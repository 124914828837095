import { useMemo } from 'react';

import './StationList.scss';
import StationListItem from './item/StationListItem';

export default function StationList({
  currentStation,
  setCurrentStation,
  routePoints,
  stationsInScope,
  activeFilters,
  context,
  yourPosition
}) {
  const filteredStations = useMemo(() => {
    if (!activeFilters?.length) {
      return stationsInScope[context];
    }

    return stationsInScope[context].filter((station) => {
      const features = Object.values(station.attributes.features)
        .filter(val => typeof val === 'object')
        .map(item => Object.entries(item))
        .flat()
        .filter(([key, val]) => val && typeof val === 'boolean')
        .filter(([key, val]) => val && typeof val === 'boolean')
        .map(([key, val]) => key);

      return activeFilters?.every(f => features.includes(f));
    });
  }, [activeFilters, stationsInScope]);

  return (
    <div className="station-list">
      <span className="station-list__head">Znaleziono na trasie: <strong>{filteredStations.length} stacji</strong></span>
      <div className="station-list__wrap">
        {filteredStations?.map(station => (
          <StationListItem yourPosition={yourPosition} key={station.id} routePoints={routePoints} station={station} setCurrentStation={setCurrentStation} currentStation={currentStation} />
        ))}
      </div>
    </div>
  );
}
