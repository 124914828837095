import './PlanRoute.scss';
import RoutePoints from '../../route-points/RoutePoints';
import Filters from '../../filters/Filters';
import StationList from '../../stations/list/StationList';

export default function PlanRoute({
  currentStation,
  setCurrentStation,
  activeFilters,
  setActiveFilters,
  routePoints,
  setRoutePoints,
  stationsInScope,
  setFindStationTarget,
  yourPosition
}) {
  return (
    <div className="plan-route">
      <RoutePoints setRoutePoints={setRoutePoints} setFindStationTarget={setFindStationTarget} />
      <div className="plan-route__wrapper">
        <Filters absolute activeFilters={activeFilters} setActiveFilters={setActiveFilters} stations={
          <StationList
            routePoints={routePoints}
            setCurrentStation={setCurrentStation}
            currentStation={currentStation}
            stationsInScope={stationsInScope}
            activeFilters={activeFilters}
            context="byRoute"
            yourPosition={yourPosition}
          />
        } />
      </div>
    </div>
  );
}
