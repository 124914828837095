import { useMemo } from 'react';
import './StationListItem.scss';

export default function StationListItem({
  station,
  setCurrentStation,
  routePoints,
  yourPosition
}) {
  const {
    logo: {
      data: {
        attributes: {
          url: logo
        }
      }
    },
    address: {
      street,
      city
    },
    station_number,
    coordinates: {
      latitude: lat,
      longitude: lng
    },
    opening_hours,
    phone,
    warning
  } = station.attributes;

  const distance = useMemo(() => {
    if (yourPosition) {
      const meters = window.google.maps.geometry.spherical.computeDistanceBetween(
        { lat: parseFloat(lat), lng: parseFloat(lng) },
        yourPosition
      );

      if (meters < 1000) {
        return `${parseInt(meters, 10)} m`;
      }

      return `${parseInt(meters / 1000, 10)} km`;
    }
  }, [yourPosition, lat, lng]);

  return (
    <div className="station-list-item">
      <div className="station-list-item__head">
        <img className="station-list-item__logo" src={`${process.env.REACT_APP_API_URL}/${logo}`} alt="logo" />
        <div className="station-list-item__address">
          <strong>{city}</strong>
          <span>{street}</span>
        </div>
        <div className="station-list-item__number">
          <span>Numer stacji</span>
          <strong>{station_number}</strong>
        </div>
      </div>
      <div className="station-list-item__content">
        <div className="station-list-item__left-col">
          <div className={`station-list-item__hours ${opening_hours.is_24h ? 'station-list-item__hours--24h' : ''}`}>
            <img src="/images/icons/common/clock.svg" alt="Ikona zegara" />
            {opening_hours.is_24h ? '24h' : (
              <>
                {!!opening_hours.working_days_start && <>pon-pt: {opening_hours.working_days_start.slice(0, 5)} - {opening_hours.working_days_end.slice(0, 5)}<br /></>}
                {!!opening_hours.saturday_start && <>sob: {opening_hours.saturday_start.slice(0, 5)} - {opening_hours.working_days_end.slice(0, 5)}<br /></>}
                {!!opening_hours.sunday_start && <>niedz: {opening_hours.sunday_start.slice(0, 5)} - {opening_hours.working_days_end.slice(0, 5)}<br /></>}
              </>
            )}
          </div>
          <div className="station-list-item__tel">
            <img src="/images/icons/common/phone.svg" alt="Ikona telefonu" />
            <a href={`tel:${phone}`}>{phone}</a>
          </div>
        </div>
        <div className="station-list-item__right-col">
          {distance && (
            <div className="station-list-item__distance">
              {distance}
            </div>
          )}
          <div className="station-list-item__drive">
            <a className="station-list-item__drive-button" href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`} target="_blank" rel="noreferrer">
              Jak dojechać
              <img src="/images/icons/common/arrow-top-right.svg" alt="Strzałka" />
            </a>
          </div>
        </div>
        {!!warning && (
          <div className="station-list-item__alert">
            <img src="/images/icons/common/warning.svg" alt="Ikona alertu" />
            <p>{warning}</p>
          </div>
        )}
        <button className="button station-list-item__details-button" type="button" onClick={() => setCurrentStation(station)}>
          Zobacz szczegóły
        </button>
      </div>
    </div>
  );
}
