import React, { useState, useEffect, useRef } from 'react';
import { ReactSortable } from 'react-sortablejs';
import './RoutePoints.scss';
import RoutePointsItem from './item/RoutePointsItem';

export default function RoutePoints({ setRoutePoints, setFindStationTarget }) {
  const [state, setState] = useState([{ id: 1 }, { id: 2 }]);
  const wrap = useRef(null);
  const points = useRef([]);
  const sortUpdatedFlag = useRef(false);

  const addPoint = () => {
    setState([...state, { id: Date.now() }]);

    points.current = [...points.current, null];

    setTimeout(() => {
      wrap.current.ref.current.scrollTo(0, 100000);
    }, 100);
  };

  useEffect(() => {
    if (sortUpdatedFlag.current) {
      const sortedPoints = [];

      state.forEach((item) => {
        sortedPoints.push(points.current.find(point => point?.id === item.id) || null);
      });

      sortUpdatedFlag.current = false;
      points.current = sortedPoints;
    }
  }, [state, points, sortUpdatedFlag]);

  const applyRoute = () => {
    setFindStationTarget(null);

    setRoutePoints(points.current?.filter(point => !!point).map(point => point.coords));
  };

  return (
    <div className="route-points">
      <ReactSortable className="route-points__wrap" ref={wrap} animation={0} list={state} setList={setState} handle=".route-points__handler" onSort={() => sortUpdatedFlag.current = true}>
        {state.map((item, index) => (
          <div key={item.id}>
            <RoutePointsItem key={item.id} index={index} id={item.id} points={points} state={state} setState={setState} />
          </div>
        ))}
      </ReactSortable>
      <button className="route-points__add-point-button" type="button" onClick={addPoint} disabled={state.length >= 8}>
        <img src="/images/icons/common/add.svg" alt="Ikona plusa" />
        Dodaj miejsce docelowe
      </button>
      <button className="button route-points__button" type="button" onClick={applyRoute}>
        Wyznacz trasę
      </button>
    </div>
  );
}
