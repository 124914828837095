import { useState, useEffect, useCallback } from 'react';
import './App.scss';
import Map from './components/map/Map';
import Tabs from './components/tabs/Tabs';
import StationProfile from './components/stations/profile/StationProfile';

function App() {
  const url = new URL(window.location);
  const filters = url.searchParams.get('filters');
  const [currentStation, setCurrentStation] = useState(null);
  const [yourPosition, setYourPosition] = useState(null);
  const [routePoints, setRoutePoints] = useState(null);
  const [stations, setStations] = useState(null);
  const [activeFiltersFindStation, setActiveFiltersFindStation] = useState(filters === 'null' ? null : filters.split(','));
  const [activeFiltersPlanRoute, setActiveFiltersPlanRoute] = useState([]);
  const [findStationTarget, setFindStationTarget] = useState(null);
  const [context, setContext] = useState('byStation');
  const [stationsInScope, setStationsInScope] = useState({
    byRoute: [],
    byStation: []
  });

  const updateCurrentStation = useCallback((stationName) => {
    setCurrentStation(stations.find(station => station.attributes.name.toLowerCase() === decodeURIComponent(stationName)) || null);
  }, [stations]);

  const onMessageHandler = useCallback((event) => {
    if (event.data.type === 'update-station') {
      const { data } = event.data;

      updateCurrentStation(data.station);
    }

    if (event.data.type === 'update-filters') {
      const { data } = event.data;

      setActiveFiltersFindStation(data);
    }
  }, [stations]);

  useEffect(() => {
    const stationName = url.searchParams.get('target_station');

    window.addEventListener('message', onMessageHandler);

    if (stationName && stations?.length) {
      updateCurrentStation(stationName);
    }

    if ('geolocation' in navigator) {
      if (!yourPosition) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            setYourPosition({ lat, lng });
          },
          (error) => {
            console.error('Error getting user location:', error);
          }
        );
      }
    } else {
      console.error('Geolocation is not supported by this browser.');
    }

    return () => {
      window.removeEventListener('message', onMessageHandler);
    }
  }, [stations]);

  const getStations = useCallback(async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/stations?populate=logo,address,coordinates,opening_hours,features.fuels,features.cards,features.services`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`
      }
    });

    const json = await res.json();

    setStations(json.data);

    return stations;
  }, [stations]);

  useEffect(() => {
    if (!stations) {
      getStations();
    }
  }, [getStations, stations]);

  useEffect(() => {
    if (activeFiltersFindStation !== null) {
      window.parent.postMessage({
        type: 'update-filters',
        data: activeFiltersFindStation
      }, '*');
    }
  }, [activeFiltersFindStation]);

  return (
    <div className="app">
      {currentStation && (
        <button className="app__back-button" type="button" onClick={() => setCurrentStation(null)}>
          <img src="/images/icons/common/arrow-right-red.svg" alt="Srzałka" />
          Powrót
        </button>
      )}
      <div className={`app__content ${currentStation ? 'app__content--margin' : ''}`}>
        <div className="left-col">
          {currentStation && <StationProfile station={currentStation} />}
          <div className={`left-col__tabs-wrap ${currentStation ? 'left-col__tabs-wrap--hidden' : ''}`}>
            <Tabs
              stations={stations}
              setCurrentStation={setCurrentStation}
              currentStation={currentStation}
              activeFiltersFindStation={activeFiltersFindStation}
              setActiveFiltersFindStation={setActiveFiltersFindStation}
              activeFiltersPlanRoute={activeFiltersPlanRoute}
              setActiveFiltersPlanRoute={setActiveFiltersPlanRoute}
              routePoints={routePoints}
              setRoutePoints={setRoutePoints}
              stationsInScope={stationsInScope}
              setStationsInScope={setStationsInScope}
              setFindStationTarget={setFindStationTarget}
              findStationTarget={findStationTarget}
              setContext={setContext}
              yourPosition={yourPosition}
            />
          </div>
        </div>
        <div className="right-col">
          <Map
            stations={stations}
            routePoints={routePoints}
            setCurrentStation={setCurrentStation}
            currentStation={currentStation}
            stationsInScope={stationsInScope}
            setStationsInScope={setStationsInScope}
            activeFiltersFindStation={activeFiltersFindStation}
            activeFiltersPlanRoute={activeFiltersPlanRoute}
            setFindStationTarget={setFindStationTarget}
            findStationTarget={findStationTarget}
            context={context}
            yourPosition={yourPosition}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
