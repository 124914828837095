import { useEffect, useState } from 'react';
import './Tabs.scss';
import FindStation from './find-station/FindStation';
import PlanRoute from './plan-route/PlanRoute';

export default function Tabs({
   currentStation,
   setCurrentStation,
   activeFiltersFindStation,
   setActiveFiltersFindStation,
   activeFiltersPlanRoute,
   setActiveFiltersPlanRoute,
   routePoints,
   setRoutePoints,
   stationsInScope,
   setFindStationTarget,
   findStationTarget,
   setContext,
   yourPosition
}) {
  const [activeTab, setActiveTab] = useState('find');

  useEffect(() => {
    if (activeTab === 'find') {
      setContext('byStation');
    }

    if (activeTab === 'plan') {
      setContext('byRoute');
    }
  }, [activeTab]);

  return (
    <>
      <div className="tabs">
        <div className="tabs__wrap">
          <button className={`tabs__tab ${activeTab === 'find' ? 'tabs__tab--active' : ''}`} type="button" onClick={() => setActiveTab('find')}>
            <img className="tabs__tab-icon" src="/images/icons/common/search.svg" alt="Ikona lupki" />
            Znajdź stację
          </button>
          <button className={`tabs__tab ${activeTab === 'plan' ? 'tabs__tab--active' : ''}`} type="button" onClick={() => setActiveTab('plan')}>
            <img className="tabs__tab-icon" src="/images/icons/common/routing.svg" alt="Ikona trasy" />
            Planuj trasę
          </button>
        </div>
        <div className="tabs__contents">
          <div className={`tabs__content ${activeTab !== 'find' ? 'tabs__content--hidden' : ''}`}>
            <FindStation
              setCurrentStation={setCurrentStation}
              currentStation={currentStation}
              activeFilters={activeFiltersFindStation}
              setActiveFilters={setActiveFiltersFindStation}
              setFindStationTarget={setFindStationTarget}
              findStationTarget={findStationTarget}
              stationsInScope={stationsInScope}
              yourPosition={yourPosition}
            />
          </div>
          <div className={`tabs__content ${activeTab !== 'plan' ? 'tabs__content--hidden' : ''}`}>
            <PlanRoute
              setCurrentStation={setCurrentStation}
              currentStation={currentStation}
              activeFilters={activeFiltersPlanRoute}
              setActiveFilters={setActiveFiltersPlanRoute}
              routePoints={routePoints}
              setRoutePoints={setRoutePoints}
              stationsInScope={stationsInScope}
              setFindStationTarget={setFindStationTarget}
              yourPosition={yourPosition}
            />
          </div>
        </div>
      </div>
    </>
  );
}
